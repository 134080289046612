import React, { useEffect, useState } from "react";
import { parseISO } from "date-fns";
import polly from 'polly-js';

import { FetchFn, useSgConnectFetch, widgetize, WidgetPropsMapping, WithCss, useMyWidgetConfiguration } from "@sg-widgets/react-core";
import { Analytics, AnalyticCallback, List, NoDataFiller, ViewSelector } from "@sgss-ttd-widgets/components";

import * as listStyles from "@sgss-ttd-widgets/components/styles/list.less";
import * as widgetStyles from "./ttd-my-cases.less";
import { Case, CaseLastInteractionReference, DoSearchCaseResponse, NomenclatureValue, NomenclatureValuesResponse } from "../models/my-cases/do-search-case.model";
import ItemInfoTemplate from "./components/item-info-template";
import { CaseLight } from "../models/my-cases/do-search-case.model";
import { doFetch, FetchResponse } from "../common/http";
import getCasesMock from "../mocks/cases-mock";
import getInteractionsMock from "../mocks/interactions-mock";
import TitleTemplate from "./components/title-template";
import ItemTitleTemplate from "./components/item-title-template";
import { Configuration } from "../../configuration/configuration.model";
import { Interaction, InteractionChannel } from "../models/my-cases/interaction.model";
import i18n from "./i18n";
import { useSgWidgetTranslation } from "../common/use-sg-widgets-translation";
import ActionTemplate from "./components/action-template";
import ItemTextTemplate from "./components/item-text-template";
import ToolbarTemplate from "./components/toolbar-template";
import { getMergedStyles } from "../utils";

interface Props {
  items: CaseLight[];
  hasUnauthorizedAccess: boolean;
  hasDataFetchingError: boolean;
  isLoading: boolean;
  isComingSoon: boolean;
  disableLastInteractions: boolean;
  helpClick: () => void;
  analyticCallback: AnalyticCallback;
  pageChange: (page: number) => void;
}

const MyCases: React.FC<Props> = ({
  items,
  hasUnauthorizedAccess,
  hasDataFetchingError,
  isLoading,
  isComingSoon,
  disableLastInteractions,
  helpClick,
  analyticCallback,
  pageChange
}) => {
  const itemTitlePropertyPath = 'title';
  const itemIconPropertyPath = 'serviceIcon';
  const itemIconClassesPropertyPath = 'serviceIconClasses';
  const itemIconTooltipPropertyPath = 'serviceFullName';
  const [itemsState, setItemsState] = useState(items);
  const [hasUnauthorizedAccessState, setHasUnauthorizedAccessState] = useState(hasUnauthorizedAccess);
  const [hasDataFetchingErrorState, setHasDataFetchingErrorState] = useState(hasDataFetchingError);
  const [isLoadingState, setIsLoadingState] = useState(true);
  const [isComingSoonState, setIsComingSoonState] = useState(isComingSoon);
  const [hasMockDataLoadedState, setHasMockDataLoadedState] = useState(false);
  const [dataFetched, setDataFetched] = useState(false);
  const [onlyOpenedCasesState, setOnlyOpenedCasesState] = useState(false);
  const [isIndemoModeState, setIsIndemoModeState] = useState(false);
  const { fetch } = useSgConnectFetch("api.sgm-cases-widgets.v1");
  const configuration = useMyWidgetConfiguration<Configuration>();
  const { currentLanguage, t: translate } = useSgWidgetTranslation(i18n);
  const navigateAsStorageKey = "SGM.Securities.Services:navigateAsUser";

  const createPayloadSeeAllClick = Analytics.payloadFactory('buttonClick', 'Click to see all cases');
  const createPayloadCreateClick = Analytics.payloadFactory('buttonClick', 'Click to create a case');
  const createPayloadHelpClick = Analytics.payloadFactory('buttonClick', 'Click on help');
  const createPayloadItemClick = Analytics.payloadFactory('buttonClick', 'Click on a case');

  const baseUrl = configuration?.myCases.apiBaseUrl;
  const searchCaseEndpointUrl = '/api/v1/cases';
  const getWebMessageEndpointUrl = '/api/v1/messages'
  const getCallEndpointUrl = '/api/v1/calls';
  const getEmailEndpointUrl = '/api/v1/emails';
  const getNomenclatureValuesUrl ='/api/v1/nomenclature-values';
  
  useEffect(() => {
    if (items !== itemsState) {
      setItemsState(items);
    }
  }, [items]);

  useEffect(() => {
    if (hasUnauthorizedAccess !== hasUnauthorizedAccessState) {
      setHasUnauthorizedAccessState(hasUnauthorizedAccess);
    }
  }, [hasUnauthorizedAccess]);

  useEffect(() => {
    if (hasDataFetchingError !== hasDataFetchingErrorState) {
      setHasDataFetchingErrorState(hasDataFetchingError);
    }
  }, [hasDataFetchingError]);

  useEffect(() => {
    if (isLoading !== undefined &&
      isLoading !== null &&
      isLoading !== isLoadingState) {
      console.log(isLoading);
      setIsLoadingState(isLoading);
    }
  }, [isLoading]);

  useEffect(() => {
    if (baseUrl && !dataFetched) {
      loadData();
      setDataFetched(true);
    }
  }, [baseUrl]);


  const processData = (cases: Case[], interactions: { interaction: Interaction, channel: InteractionChannel }[], nomenclatureValues: NomenclatureValue[]): CaseLight[] => {  
    const interactionIdToInteraction = interactions.reduce((acc, cur) => {
      acc[cur.interaction.id] = cur;
      return acc;
    }, {} as {[key: string]: { interaction: Interaction, channel: InteractionChannel }});
    
    const flattenedNomenclatureValues: NomenclatureValue[] = nomenclatureValues.flatMap(x => [{
      isActive: x.isActive,
      code: x.code,
      label: x.label,
      frenchLabel: x.frenchLabel
    }, ...(x.results ?? [])]);

    const codeToNomenclatureValue = flattenedNomenclatureValues.reduce((acc, cur) => {
      acc[cur.code] = cur;
      return acc;
    }, {} as { [key: string]: NomenclatureValue });
   
    return cases?.map(item => {
      const date = item?.userMetaData.lastAlertDateTime ?? item?.userMetaData.lastAlertDateTime;
      const creationDate = date ? parseISO(date) : null;
      const { interaction, channel } = interactionIdToInteraction[item.userMetaData.latestVisibleInteraction?.id] || {};
      const creatorName = 
        interaction?.metaData.createdBy?.displayName ||
        interaction?.metaData.sentBy?.displayName;
      const [senderFirstName, ...rest] = creatorName ? creatorName.split(' ') : ['', ''];
      const senderLastName = rest.join(' ');
      
      let interactionDate: Date | null = null;
      if (interaction) {
        if (interaction.metaData.updateDateTime) {
          interactionDate = parseISO(interaction.metaData.updateDateTime);
        }
        else if (interaction.metaData.sentDateTime) {
          interactionDate = parseISO(interaction.metaData.sentDateTime);
        }
        else if (interaction.metaData.initiationDateTime) {
          interactionDate = parseISO(interaction.metaData.initiationDateTime);
        }
      }

      const activityCode = item?.content?.activity;
      const subActivityCode = item?.content?.subActivity;
      let serviceIcon = '';
      let serviceIconClasses = '';
      let serviceFullName = '';

      switch (subActivityCode) {
        // Client services
        case 'SUBACTIVITY_1':
          serviceIcon = 'person';
          serviceIconClasses = 'bg-yellow-50';
          serviceFullName = 'Client Services';
          break;
        // Invoicing
        case 'SUBACTIVITY_2':
          serviceIcon = 'description';
          serviceIconClasses = 'bg-blue-50';
          serviceFullName = 'Invoicing';
          break;
        // Trustee
        case 'SUBACTIVITY_3':
          serviceIcon = 'verified_user';
          serviceIconClasses = 'bg-green-50';
          serviceFullName = 'Trustee';
          break;
        // Clearing and Settlement
        case 'SUBACTIVITY_4':
          serviceIcon = 'payments';
          serviceIconClasses = 'bg-turquoise-50';
          serviceFullName = 'Clearing and Settlement';
          break;
        // Custody
        case 'SUBACTIVITY_5':
          serviceIcon = 'security';
          serviceIconClasses = 'bg-purple-50';
          serviceFullName = 'Custody';
          break;
        // Fund Administration
        case 'SUBACTIVITY_6':
          serviceIcon = 'pie_chart';
          serviceIconClasses = 'bg-orange-50';
          serviceFullName = 'Fund Administration';
          break;
        // Liability Management
        case 'SUBACTIVITY_7':
          serviceIcon = 'business';
          serviceIconClasses = 'bg-turquoise-50';
          serviceFullName = 'Liability Management';
          break;
        // Fund Distribution
        case 'SUBACTIVITY_8':
          serviceIcon = 'swap_horizontal_circle';
          serviceIconClasses = 'bg-orange-50';
          serviceFullName = 'Fund Distribution';
          break;
        // Regulatory Reporting
        case 'SUBACTIVITY_9':
          break;

        case 'SUBACTIVITY_10':
          serviceIcon = 'credit_card';
          serviceIconClasses = 'bg-turquoise-50';
          serviceFullName = 'Cash Management';
          break;
        default:
          break;
      }

      return {
        caseReference: item.id,
        date: creationDate,
        status: item.metaData.shortStatus,
        title: item.metaData.caseLabel,
        activity: codeToNomenclatureValue[activityCode]?.label ?? activityCode,
        subActivity: codeToNomenclatureValue[subActivityCode]?.label ?? subActivityCode,
        lastInteraction: interaction ? {
          date: interactionDate,
          senderFirstName,
          senderLastName,
          text: interaction.content.body,
          channel: channel,
          direction: interaction.metaData.direction,
          fromName: interaction.content.from?.displayName,
          toPhoneNumber: interaction.content.to?.phoneNumber,
          toName: interaction.content?.to?.displayName,
          callDate: interaction.metaData?.initiationDateTime 
            ? parseISO(interaction.metaData?.initiationDateTime) 
            : null,
          isUnread: !!item.userMetaData.alertCount  
        } : null,
        isUnread: !!item.userMetaData.alertCount,
        serviceIcon,
        serviceIconClasses,
        serviceFullName
      } as CaseLight;
    })
    .sort((a, b) => b.date?.getTime() - a.date?.getTime())
    .slice(0, 5);
  };

  const buildApiUrl = (apiEndpointUrl: string, queryParams: {[key: string]: string} = {}): string => {
    const queryParamsArray = Object.entries(queryParams).reduce(
      (acc, cur) => {
        const [key, value] = cur;
        acc.push(`${key}=${value}`);
        return acc;
      }, [] as string[]
    );

    const endpoint = `${baseUrl}${apiEndpointUrl}`;
    return queryParamsArray.length 
      ? `${endpoint}?${queryParamsArray.join('&')}`
      : endpoint;
  }

  const loadCases = async (fetch: FetchFn, onlyOpenedCases: boolean): Promise<FetchResponse<DoSearchCaseResponse>> => {
    return polly()
      .waitAndRetry(3)
      .executeForPromise(async ({ count }) => {
        const result = await doFetch<DoSearchCaseResponse>(
          fetch,
          buildApiUrl(
            searchCaseEndpointUrl,
            onlyOpenedCases ? { resolved: 'false'} : {}),
          { 
            method: 'GET',
            mode: "cors",
          }
        );

        if (result.status !== 'loaded' && count < 3) {
          throw result;
        }

        return result;
      });
  }

  const loadNomenclatureValues = async (fetch: FetchFn): Promise<FetchResponse<NomenclatureValuesResponse>> => {
     const queryParams = {
      field: 'activity',
    };

    return polly()
      .waitAndRetry(3)
      .executeForPromise(async ({ count }) => {
        const result = await doFetch<NomenclatureValuesResponse>(
          fetch,
          buildApiUrl(
            getNomenclatureValuesUrl,
            queryParams),
          { 
            method: 'GET',
            mode: "cors",
          }
        );

        if (result.status !== 'loaded' && count < 3) {
          throw result;
        }

        return result;
      });
  }

  const loadLatestInteraction = async (fetch: FetchFn, reference: CaseLastInteractionReference): 
    Promise<{ response: FetchResponse<Interaction>, channel: InteractionChannel }> => {
    let targetEndpoint = null;
    let channel: InteractionChannel = "message";

    switch (reference.type) {
      case "MESSAGE":
        targetEndpoint = getWebMessageEndpointUrl;
        channel = "message";
        break;
      case "CALL":
        targetEndpoint = getCallEndpointUrl;
        channel = "phone call";
        break;
      case "EMAIL":
        targetEndpoint = getEmailEndpointUrl;
        channel = "mail";
        break;
    }

    const queryParams = {
      populateFields: 'metaData%2Ccontent',
    };
    const targetUrl = buildApiUrl(
      `${targetEndpoint}/${reference.id}`,
      queryParams);
    return await doFetch<Interaction>(
      fetch,
      targetUrl,
      { 
        method: 'GET',
        mode: "cors",
      }
    ).then(response =>
      ({ response, channel }));
  }

  const loadDataFromApi = async (onlyOpenedCases: boolean): Promise<CaseLight[]> => {
    setIsLoadingState(true);
    
    if (!fetch) {
      setHasDataFetchingErrorState(true);
      return [];
    }

    const result = await Promise.all([
      loadCases(fetch, onlyOpenedCases),
      loadNomenclatureValues(fetch)
    ]);

    const [
      { status: responseStatus, data: requestData },
      nomenclatureValuesResult
    ] = result;

    if (responseStatus === 'notauthenticated' || responseStatus === 'forbidden') {
          setHasUnauthorizedAccessState(true);
          return [];
    }
    
    if (responseStatus !== 'loaded' || !requestData || !requestData.results) {
        setHasDataFetchingErrorState(true);
        return [];
    }
    
    const latestInteractionReferencesRequests = !disableLastInteractions
      ? requestData.results
        .filter(x => x.userMetaData?.latestVisibleInteraction && x.userMetaData.latestVisibleInteraction?.id)
        .map(x => loadLatestInteraction(fetch, x.userMetaData.latestVisibleInteraction))
      : [];

    const latestInteractionsResponses = await Promise.all(latestInteractionReferencesRequests);

    const processedCases = processData(
      requestData.results, 
      latestInteractionsResponses
        .filter(x => x.response.status === "loaded" && x.response.data)
        .map(x => ({ 
          interaction: x.response.data as Interaction,
          channel: x.channel 
        })),
        nomenclatureValuesResult?.data?.results ?? []);
    return processedCases;
  }

  const loadData = async (loadMocks?: boolean, onlyOpenedCases = false) => {
    if (!loadMocks && sessionStorage.getItem(navigateAsStorageKey)) {
      setIsComingSoonState(true);
      setIsLoadingState(false);
      return;
    }

    setIsIndemoModeState(!!loadMocks);
    setHasUnauthorizedAccessState(false);
    setHasDataFetchingErrorState(false);
    
    let cases: CaseLight[] = [];
    if (loadMocks) {
      cases =  processData(getCasesMock(), getInteractionsMock(), []);
    } else if (!isComingSoonState) {
      cases = await loadDataFromApi(onlyOpenedCases);
    }
   
    setIsLoadingState(false);
    setItemsState(cases);
  }

  const RefreshActionTemplate = (): JSX.Element => {
    const demoBtn = <button
      className="btn btn-default btn-lg btn-icon-start mt-1"
      onClick={() => {
        loadData(true);
        setHasMockDataLoadedState(true);
      }}>
      <i className="icon">visibility</i> {translate('ttdMyCases.demoBtn')}
    </button>;
    const retryBtn = <button
      className="btn btn-discreet-primary btn-lg btn-icon-start"
      onClick={() => loadData() }>
      <i className="icon">refresh</i> {translate('ttdMyCases.retryBtn')}
    </button>;
    
    // return isComingSoonState
    //   ? demoBtn
    //   : (<div>
    //       {retryBtn}&nbsp;{demoBtn}
    //     </div>)
    return isComingSoonState
      ? <></>
      : <div>{retryBtn}</div>
  };

  const goToLink = (url: string | undefined):void => {
    const finalUrl = `${configuration?.myCases.myCasesUrl}/${url}`;
    window
      .open(finalUrl, '_blank');
  };

  const actionsTemplate = <RefreshActionTemplate />;
  const content = 
      <ViewSelector
        itemsCount={1}
        hasUnauthorizedAccess={hasUnauthorizedAccessState}
        hasDataFetchingError={false}
        actionsTemplate={actionsTemplate}
        isComingSoon={isComingSoonState}
        language={currentLanguage}
        isLoading={isLoadingState}
        noDataFiller={
          <NoDataFiller title={translate('ttdMyCases.viewSelector.noData.title')} icon="widgets" actionsTemplate={actionsTemplate}>
            {translate('ttdMyCases.viewSelector.noData.message')}
          </NoDataFiller>
        }>
          <List<CaseLight> title={translate('ttdMyCases.title')}
            titleTemplate={<TitleTemplate isInDemoMode={hasMockDataLoadedState}/>}
            items={itemsState}
            itemTitlePropertyPath={itemTitlePropertyPath}
            itemIconPropertyPath={itemIconPropertyPath}
            itemIconClassesPropertyPath={itemIconClassesPropertyPath}
            itemIconTooltipPropertyPath={itemIconTooltipPropertyPath}
            itemTitleTemplate={<ItemTitleTemplate />}
            itemTextTemplate={<ItemTextTemplate />}
            itemHighlightPredicate={item => !!item.isUnread}
            actionsTemplate={
              <ActionTemplate helpClick={() => { helpClick(); analyticCallback(createPayloadHelpClick()); } }/>}
            toolbarTemplate={
              <ToolbarTemplate createClick={() => { goToLink(configuration?.myCases.myCasesCreateCaseUrl); analyticCallback(createPayloadCreateClick()); }} />}
            itemInfoTemplate={<ItemInfoTemplate/>}
            noDataFiller={
              <NoDataFiller title={translate('ttdMyCases.viewSelector.noData.title')} icon="widgets" actionsTemplate={actionsTemplate}>
                {translate('ttdMyCases.viewSelector.noData.message')}
              </NoDataFiller>
            }
            dataFetchingErrorFiller={
              <NoDataFiller title={translate('ttdMyCases.viewSelector.technicalIssue.title')} icon="error_outline" actionsTemplate={actionsTemplate}>
                {translate('ttdMyCases.viewSelector.technicalIssue.message')}
              </NoDataFiller>
            }
            onClick={ data => { goToLink(`${configuration?.myCases.myCasesSeeCaseDetails}/${data.item.caseReference}`); analyticCallback(createPayloadItemClick(data.item.title)); } }
            onTitleClick={() => { goToLink(configuration?.myCases.myCasesSeeAllUrl); analyticCallback(createPayloadSeeAllClick()); }}
            pages={0}
            onPageChange={(page) => pageChange(page)}
            isLoading={isLoadingState}
            hasDataFetchingError={hasDataFetchingErrorState}
          />
        </ViewSelector>;
  
  const styles = getMergedStyles(listStyles, widgetStyles);
  return (
    <WithCss styles={styles}>
      <div className={`wrapper d-flex bg-lvl1 ${ isComingSoonState ? 'align-items-center' : '' }`}>
        { content }
      </div>
    </WithCss>
  );
};

widgetize("ttd-my-cases", MyCases, {
  items: WidgetPropsMapping.asObject({
    description: "The items",
  }),
  hasUnauthorizedAccess: WidgetPropsMapping.asObject({
    description: "Indicates wether the access in authorized or not",
  }),
  hasDataFetchingError: WidgetPropsMapping.asObject({
    description: "Indicates if there was an error when trying to fetch the data",
  }),
  isLoading: WidgetPropsMapping.asObject({
    description: "Indicates if there is a load operation pending",
  }),
  isComingSoon: WidgetPropsMapping.asObject({
    description: "Indicates if the widget is in a WIP state",
  }),
  disableLastInteractions: WidgetPropsMapping.asObject({
    description: "Indicates if the widget must load the last interactions",
  }),
  helpClick: WidgetPropsMapping.asEventEmitter("help-click", {
    description: "Event occurring when the help button is clicked",
  }),
  analyticCallback: WidgetPropsMapping.asEventEmitter("analytic-callback", {
    description: "Event occurring when an event should be logged to the analytic module for monitoring",
  }),
  pageChange: WidgetPropsMapping.asEventEmitter("page-change", {
    description: "Event occurring when a page is selected",
  })
});
